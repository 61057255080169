import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react"
import { useQueryParam } from "use-query-params";
import LayoutLogged from "../components/layout/logged";
import PagePadding from "../components/layout/page-padding";
import ReferrerLicense from "../components/licenses/referrer";
import { getCurrentUser } from "../services/auth";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { formatDateTime } from "../services/date";

export default function LicensePage() {
  const [id] = useQueryParam("id");
  const [license, setLicense] = useState();
  const [assigned, setAssigned] = useState();

  const licenses = {
    referrer: 'Regulamento afiliados',
  };

  useEffect(() => {
    if (!id) return;
    const user = getCurrentUser();

    setLicense(licenses[id]);
    setAssigned(user.licenses.find(license => license.license === id));
  }, [id]);

  return (
    <LayoutLogged title={license}>
      <PagePadding>
        <Container component="main" maxWidth="md">
          {assigned && (
            <table>
              <tr>
                <td><CheckCircleIcon style={{color: 'green'}}/></td>
                <td style={{color: 'green'}}>Assinado {formatDateTime(assigned.created_at)}</td>
              </tr>
            </table>
          )}
          {id === 'referrer' && <ReferrerLicense />}
        </Container>
      </PagePadding>
    </LayoutLogged>
  );
}